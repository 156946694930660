@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: #EFEFEF !important;
}

a {
  color: #000;
  text-decoration: none;
}

/* Drawer */
.MuiPaper-root {
  background-color: #fafafa !important;
  border-right: none !important;
}

.slide-header {
  display: flex !important;
  justify-content: space-around !important;
}

/* Darkmode Btn */
.MuiFormGroup-root {
  position: absolute !important;
  bottom: 20px !important;
  right: 0;
}

.name {
  font-weight: 600;
  font-size: clamp(2.2rem, 2.5rem, 3.2vw);
}

.name > span {
  color: #F65158;
}

.intro {
  margin-top: 1rem;
  font-size: clamp(1.3rem, 1.3rem, 2vw);
}

.aboutInfo {
  margin-top: 1.5rem;
  margin-right: 3rem;
  font-size: clamp(0.9rem, 1rem, 2vw);
}

.aboutInfo span {
  font-weight: 600;
  color: #2C68C2FF !important;
}

.contactInfo {
  margin-top: 1.5rem;
  font-size: clamp(0.9rem, 1rem, 2vw);
}

.socialBtn {
  cursor: pointer;
  font-size: 1.6rem !important;
  margin-right: 0.3rem !important;
  text-decoration: none;
}

.skills-badges {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 5px;
  align-items: center;
  justify-content: flex-start;
}

.skills-badges > p {
  margin: 3px;
  color: #fff;
  font-size: 13px;
  padding: 1px 8px;
  border-radius: 10px;
  background-color: #0288D1;
}

.errorPage {
  height: 100vh;
  display: flex;
  line-height: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.errorPage > h1 {
  color: #D81B60;
  font-size: clamp(100px, 25vw, 200px);
}

.errorPage > h2 {
  font-weight: 400;
  font-size: clamp(30px, 4vw, 50px);
}

/* ------------- */
/* media queries */
/* ------------- */

/* main section styling */
@media only screen and (max-width: 990px) {
  /* main div (homepage parent div) classname */
  .css-1cg8a21 {
    padding-left: 6rem !important;
  }
}

@media only screen and (max-width: 867px) {
  /* main div (homepage parent div) */
  .css-1cg8a21 {
    height: unset !important;
    margin-top: 3rem !important;
    padding-left: unset !important;
    flex-direction: column-reverse !important;
  }

  /* home page content's div className */
  .css-3q8u7t {
    /* justify-content: flex-start !important; */
    /* padding: 0.5rem 0 0.5rem 1rem  !important; */
    text-align: center !important;
  }

  .css-8wguht {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  } 

  /* main section rajpatel1-Img styling */
  .css-3q8u7t > img{
    margin-left: unset !important;
    width: 20rem !important;
    margin-top: 1rem;
  }

  /* homepage buttons styling */
  .css-w4z10b-MuiStack-root {
    display: flex !important;
    margin-top: unset !important;
    justify-content: flex-start !important;
  }

  /* button div's class */
  .css-1yjo05o {
    justify-content: flex-start !important;
  }

  /* about page styling (effect in all pages) */
  .css-ub184k {
    height: unset !important;
    padding-left: unset !important;
    flex-direction: column-reverse !important;
  }

  .aboutInfo {
    margin-right: unset !important;
  }
}

/* homepage content & image styling */
@media only screen and (max-width: 1120px) {
  /* home page content's div className */
  .css-3q8u7t {
    margin-left: 0 !important;
    margin: 0 !important;
  }
}